import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { MainNav, MobileNav } from "../components/UI";
import { PoemPages } from "../components/Content";
import Cursor from "../components/Cursor/Cursor";
import "../styles/globals.scss";

const Main = (props) => {
  const { windowDimensions, books, match } = props;
  const { params, url } = match;
  const { book, page } = params;
  const [activeBook, setActiveBook] = useState(0);
  const [cursorMode, setCursorMode] = useState("cross");
  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    const animate = setInterval(() => {
      if (url === "/" && books) {
        if (activeBook === books.length - 1) {
          setActiveBook(0);
        } else {
          setActiveBook(activeBook + 1);
        }
      }
    }, 6000);

    return () => {
      clearInterval(animate);
    };
  }, [activeBook, setActiveBook, books, url]);

  if (!books) {
    return null;
  }

  // Set the active book to book ID from url
  const activeBookUID = books[activeBook].uid;
  if (activeBookUID !== book && book) {
    //   console.log("Set book")
    const bookID = books
      .map((index) => {
        return index.uid;
      })
      .indexOf(book);
    setActiveBook(bookID);
  }

  return (
    <Fragment>
      <Helmet>
        <meta name="theme-color" content={(books[activeBook].data.colour)} />
      </Helmet>

      {!isTouchDevice ? <Cursor cursorMode={cursorMode} /> : ""}

      <MainNav
        setCursorMode={setCursorMode}
        windowDimensions={windowDimensions}
        books={books}
        activeBook={activeBook}
        setActiveBook={setActiveBook}
        book={book}
        url={url}
      />
      {windowDimensions.width < 769 ? (
        <MobileNav
          windowDimensions={windowDimensions}
          activeBook={activeBook}
          books={books}
          page={page}
          setActiveBook={setActiveBook}
        />
      ) : (
        ""
      )}
      <PoemPages
        setCursorMode={setCursorMode}
        activeBook={activeBook}
        page={page}
        books={books}
        windowDimensions={windowDimensions}
      />
    </Fragment>
  );
};

export default Main;
