import React from 'react';
import classes from './poemPages.module.scss'
import classNames from "classnames/bind";
import { NavLink } from 'react-router-dom';

const PoemPages = (props) => {
    const c = classNames.bind(classes);
    const { activeBook, books, page, windowDimensions, setCursorMode } = props;

    if (!page || !books) {return null}

    const leftPage = books[activeBook].data.body[ page ].primary;
    const rightPage = (books[activeBook].data.body[ parseInt(page) + 1 ]) ? books[activeBook].data.body[ parseInt(page) + 1 ].primary : null;

    const leftPageText = leftPage.poem_text[0].text;
    const rightPageText = (rightPage) ? rightPage.poem_text[0].text : "";
    const leftPageTitle = ((leftPage.poem_title[0]) ? leftPage.poem_title[0].text : "") + ((leftPage.poem_title_multiline[0]) ? leftPage.poem_title_multiline[0].text : "");
    const rightPageTitle = (rightPage) ? ((rightPage.poem_title[0]) ? rightPage.poem_title[0].text : "") + ((rightPage.poem_title_multiline[0]) ? rightPage.poem_title_multiline[0].text : "") : ""
    
    const nextPage = parseInt(page) + 2;
    const prevPage = parseInt(page) - 2;

    let leftLink;
    let rightLink;

    if (!leftLink) {
        if (prevPage < 0) {
            if (activeBook === 0) {
                leftLink = '/' + books[books.length - 1].uid + '/' + (books[books.length - 1].data.body.length -1);
            } else {
                leftLink = '/' + books[activeBook-1].uid + '/' + (books[activeBook-1].data.body.length -1);
            }
        } else {
            leftLink = '/' + books[activeBook].uid + '/' + prevPage;
        }
    }

    if (!rightLink) {
        if (nextPage > books[activeBook].data.body.length - 1) {
            if (activeBook === books.length-1) {
                rightLink = '/' + books[0].uid + '/0';
            } else {
                rightLink = '/' + books[activeBook + 1].uid + '/0';
            }
        } else {
            rightLink = '/' + books[activeBook].uid + '/' + nextPage;
        }
    }
   

    return (
        <div className={c({spread: true})}>
            <NavLink 
            className={c({page: true})}
            to={leftLink}
            onMouseEnter={() => {setCursorMode('left')}}
            onClick={() => {window.scrollTo(0,0)}}
            >
                <pre className={c({japanese: leftPage.japanese})}>{leftPageText}</pre>
                <pre className={c({title: true})}>{leftPageTitle}</pre>
            </NavLink>
            {(windowDimensions.width < 769) ? "" :
            <NavLink 
            className={c({page: true})}
            to={rightLink}
            onMouseEnter={() => {setCursorMode('right')}}
            onClick={() => {window.scrollTo(0,0)}}
            >
                <pre className={c({japanese: rightPage && rightPage.japanese})}>{rightPageText}</pre>
                <pre className={c({title: true})}>{rightPageTitle}</pre>
            </NavLink>
            }
        </div>
    );
};

export default PoemPages;