// import "./styles/_globals.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import Main from "./views/Main";
import Prismic from "@prismicio/client";

ReactGA.initialize('UA-149914976-3');
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname }); 
  ReactGA.pageview(location.pathname); 
});

function App() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const apiEndpoint = "https://doneForTheDoing.cdn.prismic.io/api/v2";
  const accessToken = "";
  const Client = Prismic.client(apiEndpoint, { accessToken });
  const [siteData, setSiteData] = useState(null);

  

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "book"),
        { 
          orderings: "[my.book.place_in_list]",
          pageSize: 30
      }
      );
      if (response) {
        console.log(response);
        setSiteData(response.results);
      }
    };

    if (!siteData) {
      fetchData();
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [Client, siteData]);

  return (
    
      
    <Fragment>
      <BrowserRouter basename="/">
        <Switch key="main-section">
          <Route
            exact
            path="/"
            render={(props) => (
              <Main
                windowDimensions={windowDimensions}
                books={siteData}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/:book/:page"
            render={(props) => (
              <Main
                windowDimensions={windowDimensions}
                books={siteData}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/about"
            render={(props) => (
              <Main
                windowDimensions={windowDimensions}
                books={siteData}
                {...props}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </Fragment>
    
  );
}

export default App;
