import React from "react";
import useMousePosition from "./useMousePosition";
import classes from "./cursor.module.scss";
import classNames from "classnames/bind";

const Cursor = (props) => {
  const { cursorMode } = props;
  const c = classNames.bind(classes);
  const { clientX, clientY } = useMousePosition();

  
  return (
    <div 
      style={{ 
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: "none"
      }}
    >
      <div
        className={c({cursor: true}, {cross: cursorMode === "cross"}, {left: cursorMode === "left"}, {right: cursorMode === "right"}, {hidden: clientX === 0 && clientY === 0})}
        style={{
          left: clientX,
          top: clientY,
        }}
      >
        <div className={c({ line: true }, {top: true})}></div>
        <div className={c({ line: true }, {bottom: true})}></div>
      </div>
       
    </div>
  );
};

export default Cursor