import React, { Fragment } from "react";
import classNames from "classnames/bind";
import classes from "./mainNav.module.scss";
import { NavLink } from "react-router-dom";
import { BooksList } from "..";
import { AboutText } from "../../Content";

const MainNav = (props) => {
  const c = classNames.bind(classes);
  const { books, activeBook, windowDimensions, book, url, setActiveBook, setCursorMode } =
    props;
  const bookData = books[activeBook].data;
  const bookColor = bookData.colour;
  const bookTitle = bookData.book_title[0].text;

  return (
    <Fragment>
      <div
        className={c({ background: true }, { collapsed: book })}
        style={{
          backgroundColor: bookColor,
          minHeight:
            windowDimensions.width < 769 ? "50px" : windowDimensions.height,
        }}
        onMouseEnter={() => {setCursorMode('cross')}}

      >
       
        <BooksList
          books={books}
          url={url}
          activeBook={activeBook}
          setActiveBook={setActiveBook}
          windowDimensions={windowDimensions}
        />
        <AboutText
          url={url}
        />
        <div
          className={c({ navBar: true })}
          style={{
            width:
              windowDimensions.width < 769
                ? windowDimensions.width
                : windowDimensions.height,
            backgroundColor: bookColor,
          }}
        >
          <NavLink
            to="/about"
            className={c({ button: true }, { active: url === "/about" })}
          >
            <p>About</p>
          </NavLink>
          <div
            className={c(
              { title: true },
              { visible: book && windowDimensions.width > 768 }
            )}
          >
            <p>
              {bookTitle}
              {(bookTitle === "The Breath in the Flute" ? (<span className={c({ japanese: true })}> 笛の息</span>) : '')}
            </p>
          </div>
          <NavLink
            to="/"
            className={c({ button: true }, { active: url === "/" })}
            style={{
              justifyContent: "flex-end",
            }}
          >
            <p>Contents</p>
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
};

export default MainNav;
