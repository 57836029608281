import React from "react";
import classes from "./aboutText.module.scss";
import classNames from "classnames/bind";

const AboutText = (props) => {
    const {url} = props;
  const c = classNames.bind(classes);

  return (
    <div className={c({container: true}, {hidden: url !== "/about"})}>
      <p className={c({first: true})}>
        ‘Done For The Doing’ is an online collection of over a thousand poems by
        Professor John White CBE. Eleven books were published in limited edition
        runs between 1992 and 2014. ‘Countdown’, his most recent collection of
        unpublished work is also included.
        <br />
        <br />
        Born in 1924, John White went straight from Ampleforth College to the
        Royal Air Force, where he became a flying instructor in Canada, going on
        to become a Spitfire pilot in England and what was then still Palestine.
        After being demobbed in 1947, he became a student under Anthony Blunt at
        the Courtauld Institute of Art. After completing his studies at the
        University of London and teaching in the Courtauld, White held a
        succession of academic posts, becoming in turn Alexander White Visiting
        Professor at the University of Chicago; Pilkington Professor of history
        of art at the University of Manchester and Director of the Whitworth Art
        Gallery; Professor and Chairman of the department of History of Art at
        Johns Hopkins University, and finally Durning Lawrence Professor of
        History of Art, Head of the department, a Vice-Provost, and subsequently
        Professor Emeritus, of University College London. During that period he
        became a Chairman of the Reviewing Committee on Export of Works of Art
        and a Member of the Armed Forces Pay Review Body. He is also the author
        of a number of books on Medieval and Renaissance art.
        <br />
        <br />
        50 years after he last piloted a Spitfire, John once again took to the
        skies becoming a glider pilot. Until recently he flew from Dunstable,
        getting his diamond badge for gliding after reaching 25,000 ft in the
        Pyrenees at the end of his first week of mountain flying at the age of
        77 and doing the first of numerous cross-country flights of 500 km or
        more when he was eighty. <br />
        <br />
        Although becoming a profound agnostic soon after leaving school and not
        a Buddhist, John developed a longstanding relationship with Shogyogi, a
        Shin (Pure Land) Buddhist temple in Southern Japan and helped to
        facilitate the formation of its London branch,{" "}
        <a
          className={c({link: true})}
          target="blank"
          href="https://www.threewheels.org.uk/"
        >
          Three Wheels
        </a>
        , of which he is a trustee. There is one particular haiku which
        succinctly sums up John’s relationship with Buddhism:
        <br />
        <br />
      </p>
      <p className={c({pullout: true})}>
        You of pure
        <br />
        faith,
        <br />
        <br />
        I,
        <br />
        who am certain
        <br />
        of nothing,
        <br />
        <br />
        travel
        <br />
        the one
        <br />
        road. <br />
        <br />
      </p>
      <p className={c({last: true})}>
        He developed a close friendship with Professor Kemmyo Taira Sato, head
        priest and director of Three Wheels, and has collaborated with him on a
        number of interesting projects. Together John and Taira created the book
        ‘The Breath in the Flute’ which was published by{" "}
        <a
          className={c({link: true})}
          target="blank"
          href="https://www.shunjusha.co.jp/"
        >
          Shunjusha
        </a>{" "}
        on behalf of Shogyoji in 2001. This collection of poetry, includes both
        previously published and new poems, all of them beautifully translated
        by Taira into Japanese, sometimes within an hour of their creation while
        travelling together by car in Japan. <br />
        <br />
        Over the last few years, John White and Taira Sato collaborated again on
        new translations of the Haiku of Basho, Buson and Issa, the three
        greatest Japanese haiku masters of the seventeenth and eighteenth
        centuries. The series was published last year containing new
        translations of nine hundred haiku in their essential 5-7-5 syllable
        format. He started on this mammoth task after his ninety-second
        birthday.
        <br />
        <br />
        Both his relationship with Buddhism and flying are significant as these
        themes feature heavily in his poetry.
        <br />
        <br />
        Last year, John and I released{" "}
        <a className={c({link: true})}target="blank" href="https://fifteenwords.com/">
          Fifteen Words
        </a>
        , an interactive website of his permutation poetry. This was the first
        collection of his poetry online. As John’s significant collection of
        poetry previously existed only as a limited edition of books, we agreed
        to open this work up, sharing it with an online audience. The website
        attempts to honour the books in all possible respects, with all layout
        and design decisions dictated by the original printed series.
        <br />
        <br />
        Today, John White lives in London, still writing poetry and working with
        Taira Sato and myself on various projects.
        <br />
        <br />
        *
        <br />
        <br />
        All poetry written by{" "}
        <a
          className={c({link: true})}
          target="blank"
          href="https://en.wikipedia.org/wiki/John_White_(art_historian)"
        >
          John White
        </a>
        <br />
        Japanese translations by Taira Sato
        <br />
        Website designed by{" "}
        <a className={c({link: true})} target="blank" href="https://jessmae.uk/">
          Jess Mae Adkins
        </a>
        <br />
        Website developed by{" "}
        <a className={c({link: true})} target="blank" href="http://seamus.world">
          Seamus White
        </a>
        <br />
        All poems are subject to copyright
        <br />
        <br />
      </p>
    </div>
  );
};

export default AboutText;
