import React from "react";
import classes from "./booksList.module.scss";
import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";

const BooksList = (props) => {
  const c = classNames.bind(classes);
  const { books, url, activeBook, setActiveBook, windowDimensions } = props;
  
  return (
    <div className={c({ contents: true }, { hidden: url !== "/" })}>
      {books.map((book, index) => (
        <NavLink
          to={"/" + book.uid + "/" + (windowDimensions.width < 769 && book.data.body[0].primary.hidden_on_mobile ? "1" : "0")}
          className={c({ link: true }, { active: index === activeBook })}
          key={index}
          onMouseEnter={() => {if (windowDimensions.width > 769) {setActiveBook(index)}}}
        >
          {book.data.book_title[0].text}
          {(book.data.book_title[0].text === "The Breath in the Flute" ? (<span className={c({ japanese: true })}> 笛の息</span>) : '')}
        </NavLink>
      ))}
    </div>
  );
};

export default BooksList;
