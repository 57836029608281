import React from "react";
import classes from "./mobileNav.module.scss";
import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";

const MobileNav = (props) => {
  const c = classNames.bind(classes);
  const { activeBook, page, books } = props;
  const bookData = books[activeBook].data;
  const bookColor = bookData.colour;
  const bookTitle = bookData.book_title[0].text;

  if (!page) {
    return (
      <div
        className={c({ navBar: true }, { hidden: true })}
        style={{
          backgroundColor: bookColor,
        }}
      >
        <div className={c({ button: true })}>
          <div
            className={c({ arrowLine: true })}
            style={{
              left: "10px",
              transformOrigin: "left",
              transform: "rotate(45deg)",
              backgroundColor: "white",
            }}
          ></div>
          <div
            className={c({ arrowLine: true })}
            style={{
              left: "10px",
              transformOrigin: "left",
              transform: "rotate(-45deg)",
              backgroundColor: "white",
            }}
          ></div>
        </div>
        <div className={c({ title: true })}>
          <p>
              {bookTitle}
              {(bookTitle === "The Breath in the Flute" ? (<span className={c({ japanese: true })}> 笛の息</span>) : '')}
              </p>
        </div>
        <div className={c({ button: true })}>
          <div
            className={c({ arrowLine: true })}
            style={{
              right: "10px",
              transformOrigin: "right",
              transform: "rotate(45deg)",
              backgroundColor: "white",
            }}
          ></div>
          <div
            className={c({ arrowLine: true })}
            style={{
              right: "10px",
              transformOrigin: "right",
              transform: "rotate(-45deg)",
              backgroundColor: "white",
            }}
          ></div>
        </div>
      </div>
    );
  }

  const currentBookPages = bookData.body;
  let currentBookMobilePages = [];
  let currentMobilePageIndex;
  currentBookPages.map((pageTemp, index) => {
    if (!pageTemp.primary.hidden_on_mobile) {
      currentBookMobilePages.push(pageTemp);
      currentBookMobilePages[currentBookMobilePages.length - 1].id = index;
      if (index === parseInt(page)) {
        currentMobilePageIndex = currentBookMobilePages.length - 1;
      }
    }
    return null;
  });

  const nextBook =
    activeBook === books.length - 1 ? books[0] : books[activeBook + 1];

  const nextBookPages = nextBook.data.body;
  let nextBookMobilePages = [];
  nextBookPages.map((pageTemp, index) => {
    if (!pageTemp.primary.hidden_on_mobile) {
      nextBookMobilePages.push(pageTemp);
      nextBookMobilePages[nextBookMobilePages.length - 1].id = index;
    }
    return null;
  });

  const prevBook =
    activeBook === 0 ? books[books.length - 1] : books[activeBook - 1];

  const prevBookPages = prevBook.data.body;
  let prevBookMobilePages = [];
  prevBookPages.map((pageTemp, index) => {
    if (!pageTemp.primary.hidden_on_mobile) {
      prevBookMobilePages.push(pageTemp);
      prevBookMobilePages[prevBookMobilePages.length - 1].id = index;
    }
    return null;
  });

  const leftLink =
    currentMobilePageIndex === 0
      ? "/" +
        prevBook.uid +
        "/" +
        prevBookMobilePages[prevBookMobilePages.length - 1].id
      : "/" +
        books[activeBook].uid +
        "/" +
        currentBookMobilePages[currentMobilePageIndex - 1].id;

  const rightLink =
    currentMobilePageIndex === currentBookMobilePages.length - 1
      ? "/" + nextBook.uid + "/" + nextBookMobilePages[0].id
      : "/" +
        books[activeBook].uid +
        "/" +
        currentBookMobilePages[currentMobilePageIndex + 1].id;

  return (
    <div
      className={c({ navBar: true }, { hidden: false })}
      style={{
        backgroundColor: bookColor,
      }}
    >
      <NavLink to={leftLink} className={c({ button: true })} onClick={() => {window.scrollTo(0,0)}}>
        <div
          className={c({ arrowLine: true })}
          style={{
            left: "10px",
            transformOrigin: "left",
            transform: "rotate(45deg)",
            backgroundColor: "white",
          }}
        ></div>
        <div
          className={c({ arrowLine: true })}
          style={{
            left: "10px",
            transformOrigin: "left",
            transform: "rotate(-45deg)",
            backgroundColor: "white",
          }}
        ></div>
      </NavLink>
      <div className={c({ title: true })}>
        <p>
            {bookTitle}
            {(bookTitle === "The Breath in the Flute" ? (<span className={c({ japanese: true })}> 笛の息</span>) : '')}
        </p>
      </div>
      <NavLink to={rightLink} className={c({ button: true })} onClick={() => {window.scrollTo(0,0)}}>
        <div
          className={c({ arrowLine: true })}
          style={{
            right: "10px",
            transformOrigin: "right",
            transform: "rotate(45deg)",
            backgroundColor: "white",
          }}
        ></div>
        <div
          className={c({ arrowLine: true })}
          style={{
            right: "10px",
            transformOrigin: "right",
            transform: "rotate(-45deg)",
            backgroundColor: "white",
          }}
        ></div>
      </NavLink>
    </div>
  );
};

export default MobileNav;
